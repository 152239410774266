var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c(
        "a-tabs",
        { attrs: { "active-key": _vm.keyval }, on: { change: _vm.callback } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "待入职" } },
            [
              _vm.keyval == 1
                ? _c("InductionTabel", {
                    attrs: {
                      "screen-roster-list-data": _vm.toBeEmployedData,
                      keyval: _vm.keyval,
                      "is-first": _vm.isFirst,
                    },
                    on: { changeFirst: _vm.changeFirst },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "最近入职" } },
            [
              _vm.keyval == 2
                ? _c("InductionTabel", {
                    attrs: {
                      "screen-roster-list-data": _vm.recentEmployedData,
                      keyval: _vm.keyval,
                      "is-first": _vm.isFirst,
                    },
                    on: { changeFirst: _vm.changeFirst },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "未入职" } },
            [
              _vm.keyval == 3
                ? _c("InductionTabel", {
                    attrs: {
                      "screen-roster-list-data": _vm.noInduction,
                      keyval: _vm.keyval,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }