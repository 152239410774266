var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "appForm",
        {
          ref: "appForm",
          attrs: {
            "to-list": _vm.toList,
            "screen-roster-list": _vm.screenRosterList,
            width: "400",
          },
          on: {
            getSearchCallback: _vm.getSearchCallback,
            screenRosterParams: _vm.screenRosterParams,
            exportFileList: _vm.exportFileList,
          },
        },
        [
          _c(
            "template",
            { slot: "customButton" },
            [
              _vm.keyval === "1"
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary", prefix: "add-one" },
                      on: {
                        click: function ($event) {
                          return _vm.handleAdd()
                        },
                      },
                    },
                    [_vm._v(" 添加待入职 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm.keyval === "2"
        ? _c("Alert", {
            attrs: { type: "warning", "show-icon": true, banner: true },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "alertTips" }, [
                        _vm._v("提示：最近入职列表仅显示近90天内的入职员工"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3086575068
            ),
          })
        : _vm._e(),
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "tabs-height": 36,
          "row-key": _vm.tableId ? "onJobId" : "entryApplyId",
          "table-ref": ".main-content",
          pagination: {
            current: _vm.pager.pageNum,
            pageSize: _vm.pager.pageSize,
            total: _vm.total,
            showTotal: function (total) {
              return "共 " + total + " 条数据"
            },
            showQuickJumper: true,
            showSizeChanger: true,
          },
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var scope = ref.scope
              return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
            },
          },
          {
            key: "name",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "span",
                  { staticClass: "name", attrs: { title: scope.record.name } },
                  [_vm._v(_vm._s(scope.record.name))]
                ),
              ]
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _vm.keyval === "1"
                  ? _c(
                      "span",
                      { staticClass: "option-span" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAdd(scope)
                              },
                            },
                          },
                          [_vm._v(" 确认入职 ")]
                        ),
                        _c(
                          "a-popover",
                          { attrs: { placement: "bottom", trigger: "hover" } },
                          [
                            _c("template", { slot: "content" }, [
                              _c(
                                "div",
                                { staticClass: "more-handle-btn" },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { type: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.trimTime(scope)
                                        },
                                      },
                                    },
                                    [_vm._v(" 调整入职日期 ")]
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { type: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleGiveUp(scope)
                                        },
                                      },
                                    },
                                    [_vm._v(" 放弃入职 ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "more-handle" }, [
                              _vm._v("···"),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.keyval === "2"
                  ? _c("span", [
                      _c("div", { staticClass: "more-handle-btn-box" }, [
                        _c(
                          "div",
                          { staticClass: "option" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "link" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleMatter(scope)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " 入职事项 " +
                                    _vm._s(scope.record.completeEntryMatter) +
                                    "/" +
                                    _vm._s(scope.record.allEntryMatter) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "table" },
                          [
                            scope.record.entryFormExamShow === 1
                              ? _c(
                                  "a-button",
                                  {
                                    attrs: { type: "link" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.entryAudit(scope)
                                      },
                                    },
                                  },
                                  [_vm._v(" 入职登记表审核 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.keyval === "3"
                  ? _c(
                      "a-button",
                      {
                        staticClass: "delete",
                        attrs: { type: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.editItemConfirm(scope)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("confirmForm", {
        ref: "confirmForm",
        on: {
          getFetchDataApiFn: function ($event) {
            return _vm.getFetchDataApiFn("join")
          },
        },
      }),
      _c("trimTimeForm", {
        ref: "trimTimeForm",
        on: { getFetchDataApiFn: _vm.getFetchDataApiFn },
      }),
      _c("giveUpForm", {
        ref: "giveUpForm",
        on: {
          getFetchDataApiFn: function ($event) {
            return _vm.getFetchDataApiFn("leave")
          },
        },
      }),
      _c("matter", { ref: "matter", on: { open: _vm.entryMattersEvent } }),
      _c("inductionClassForm", {
        ref: "inductionClassForm",
        attrs: {
          "is-add": _vm.isAdd,
          "entry-apply-id": _vm.entryApplyId,
          "modal-title": _vm.modalTitle,
        },
        on: {
          getFetchDataApiFn: _vm.getFetchDataApiFn,
          entryMattersEvent: _vm.entryMattersEvent,
        },
      }),
      _c("entryMatters", {
        ref: "entryMatters",
        on: { refreshTable: _vm.refreshTable, closeSon: _vm.closeSon },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }