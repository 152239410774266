// 人员模块-入转（入职）
import {
  USER
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 待入职列表
export async function waitEntryList(params) {
  return request(`${USER}/transfer/applyEntry/waitEntryList`, METHOD.GET, params)
}

// 最近入职列表
export async function recentlyHiredList(params) {
  return request(`${USER}/transfer/applyEntry/recentlyHiredList`, METHOD.GET, params)
}

// 未入职列表
export async function noEntryList(params) {
  return request(`${USER}/transfer/applyEntry/noEntryList`, METHOD.GET, params)
}

// 调整入职时间
export async function entryDate(params) {
  return request(`${USER}/transfer/applyEntry/entryDate`, METHOD.POST, params)
}

// 放弃入职
export async function noEntry(params) {
  return request(`${USER}/transfer/applyEntry/noEntry`, METHOD.POST, params)
}

// 未入职删除
export async function deleteNoEntry(params) {
  return request(`${USER}/transfer/applyEntry/deleteNoEntry`, METHOD.POST, params)
}

// 添加待入职
export async function addWaitEntry(params) {
  return request(`${USER}/transfer/applyEntry/addWaitEntry`, METHOD.POST, params)
}

// 根据身份证查询员工
export async function getStaffByIdNum(params) {
  return request(`${USER}/transfer/applyEntry/getStaffByIdNum`, METHOD.GET, params)
}
// 获取入职事项
export async function getEntryMatter(params) {
  return request(`${USER}/transfer/applyEntry/getEntryMatter`, METHOD.GET, params)
}

// 确认入职
export async function confirmEntry(params) {
  return request(`${USER}/transfer/applyEntry/confirmEntry`, METHOD.POST, params)
}

// 获取确认入职信息
export async function getEntryApplyInfo(params) {
  return request(`${USER}/transfer/applyEntry/getEntryApplyInfo`, METHOD.GET, params)
}

// 待入职导出
export async function waitEntryListExport(params) {
  return request(`${USER + '/transfer/applyEntry/waitEntryListExport'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 最近入职导出
export async function recentlyHiredListExport(params) {
  return request(`${USER + '/transfer/applyEntry/recentlyHiredListExport'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 未入职导出
export async function noEntryListExport(params) {
  return request(`${USER + '/transfer/applyEntry/noEntryListExport'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
